.highlight-container {
    align-self:center;
    padding: 2rem 1rem;
    background-color: var(--green-200);
    min-width: 100vh; /* Adjust this value as needed */
    margin-bottom: 6rem; /* Adjust this value as needed */
  }
  
  .highlight-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .highlight-item {
    padding: 1rem;
    background-color: var(--green-50);
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .highlight-item h3 {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .highlight-item p {
    font-size: 1rem;
    line-height: 1.8;
  }
  