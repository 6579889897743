.message-box-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    z-index: 9999; /* Ensure the message box is on top of everything else */
  }
  
  .message-box-body {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    text-align: center;
    z-index: 10000; /* Ensure the message box body is on top of the overlay */
  }


  