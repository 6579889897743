html, body {
  padding: 0;
}

:root {
  --green-50: #f6faf3;
  --green-100: #e4f4da;
  --green-200: #c5e5b5;
  --green-300: #a0d087;
  --green-400: #7bb163;
  --green-500: #5d9e4f;
  --green-600: #4d8644;
  --green-700: #406e3a;
  --green-800: #355c32;
  --green-900: #2a492b;
  --red-50: #FFEBEE;
  --red-100: #FFCDD2;
  --red-200: #EF9A9A;
  --red-300: #E57373;
  --red-400: #EF5350;
  --red-500: #F44336;
  --red-600: #E53935;
  --red-700: #D32F2F;
  --red-800: #C62828;
  --red-900: #B71C1C;
  --footer-height: 50px;
}

/* Import the Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

/* General Typography */
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--green-800);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: var(--green-500);
  margin-top: 0;
  margin-bottom: 16px;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

.disclaimer {
  background-color: var(--green-50);
  padding: 10px;
  margin-top: 15px;
  border-radius: 5px;
}

/* Responsive Typography */
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }
}

/* Add additional styles for your app here */

#my-table {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  table-layout: fixed;
}

#my-table th:nth-child(1),
#my-table td:nth-child(1),
#my-table th:nth-child(2),
#my-table td:nth-child(2) {
  width: 15%;
}

#my-table th:nth-child(3),
#my-table td:nth-child(3),
#my-table th:nth-child(4),
#my-table td:nth-child(4) {
  width: 35%;
}

#my-table td:nth-child(3),
#my-table td:nth-child(4) {
  word-break: break-all;
  overflow-wrap: break-word;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  /* height: 120vh; */
  overflow-y: auto;
  position: relative; /* Add this line */
  /* margin-bottom: var(--footer-height); */
}


.reading-quiz-page-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}


.content {
  width: 100%;
  max-width: 600px;
  text-align: left;
}

.TitleMenuContainer {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
}

.title-menu {
  display: flex;
  justify-content: space-between;
}

.login-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.login-icon img {
  width: 100%;
  height: auto;
}

[role="menu"] {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.login-item {
  margin-left: auto;
}


.About {
  margin-left: 20px;
}

.tab-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
}

.tab-button {
  background-color: var(--green-500);
  color: white;
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.tab-button:hover {
  background-color: var(--green-700);
  color: white;
}

.tab-button-active {
  background-color: var(--green-700);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);  /* Add a semi-transparent background */
  position: fixed;  /* Make it cover the entire screen */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;  /* Put it on top of everything else */
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}


#instruction {
  display: flex;
  flex-direction: column;
  /* other styles here */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
