  .hero-section {
    width: 100%;
    background-color: var(--green-100);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    min-height: 40vh; /* Adjust this value as needed */
    color: #1e1e1e; /* dark color for text */
    text-align: center;
    margin-bottom: 2rem; /* Adjust this value as needed */
    margin-top: 1rem; /* Adjust this value as needed */
  }
  
  .hero-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .hero-section h2 {
    font-size: 1.8rem;
    margin-bottom: 202 rem;
  }
  
  .hero-section p {
    font-size: 1.1rem;
    margin-bottom: 40px;
    }
  
  .cta-button {
    font-size: 1.25rem;
    background-color: var(--green-500);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: var(--green-400);
  }
  
  .hero-section .background {
    background-repeat: no-repeat;
    background-size: cover;
    /* Adjust the height as you need it */
    height: 600px;
    /* Positioning text to the bottom */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2rem;  /* Add some padding at the bottom */
  }

  @media screen and (max-width: 480px) {
    /* Styles for mobile devices */
  }
  