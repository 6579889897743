  .reading-quiz-page {
    padding: 20px;
    background-color: var(--green-100);
    margin-top: 0; /* Adjust this value as needed */
    min-height: 100vh; /* Set the minimum height to 100vh */
    bottom: 0;
    overflow: hidden;
    height: fit-content + margin-top;
}

  .scrollable {
    overflow-y: auto;
    height: calc(100vh - 2rem); /* Adjust the value if needed; the subtraction accounts for the margin-top */
  }
    
  
  .level-setting {
    margin-bottom: 1rem;
  }
  
  .level-setting label {
    font-weight: bold;
    color: var(--green-700);
  }
  
  .level-setting select {
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--green-400);
    background-color: var(--green-100);
    color: var(--green-800);
  }
  
  .article {
    margin: 0 auto 2rem auto;
    padding: 1rem;
    height: fit-content;
    display: flex;
    width: 100%; /* Set the desired width (percentage or fixed value) */
    max-width: 98%;
    border: 1px solid var(--green-400);
    background-color: var(--green-50);
    overflow: hidden;
  }

  #article-area {
    background-color: var(--green-100);
    width: 50%;
    float: left;
    height: auto;
    padding: 20px;
    color: var(--green-50);
    font-family: Arial, sans-serif;
    font-size: 18px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 50px;
  }
  
  .article-content {
    color: var(--green-800);
    text-align: left;
    font-size: 16px;
    line-height: 2;
  }

  .question-area {
    background-color: var(--green-100);
    width: 50%;
    float: left;
    height: auto;
    padding: 20px;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 18px;
    margin-right: 20px;
    margin-bottom: 10px;
    flex-direction: column;
    display: grid;
    overflow: hidden;
  }

  .questions {
    margin-bottom: 2rem;
  }
  
  .question {
    margin-bottom: 1rem;
  }
  
  .question h2 {
    font-weight: bold;
    color: var(--green-700);
  }
  
  .options li {
    margin-bottom: 0.5rem;
  }
  
  .options input[type='radio'] {
    margin-right: 0.5rem;
  }
  
  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 100%;
  }
  
  .arrow {
    background-color: #ccc;
    border: none;
    font-size: 20px;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .left-arrow {
    margin-right: 20px;
  }
  
  .right-arrow {
    margin-left: 20px;
  }

  .questions-wrapper {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    position: relative;
  }
  
  .questions_section {
    width: 100%;
    text-align: left;
    margin-top: 100px;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .questions-wrapper::-webkit-scrollbar {
    display: none;
  }

  .question.active {
    background-color: #f0f0f0;
  }
  
  .question-indicator {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .question-indicator-item {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .question-indicator-item.active {
    background-color: #000;
  }
  
  .flexible-height {
    max-height: calc(100vh - var(--footer-height) - 2rem); /* Subtract footer height and some extra space for better visibility */
  }
  
  .scrollable-content {
    overflow-y: auto;
    height: 100%;
    align-content: flex-start;
  }
  