.blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-post-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .blog-post-paragraph {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .blog-post {
    text-align: left;
    border-bottom: 1px solid #000; /* Change color as needed */
    margin-top: 20px;
    margin-bottom: 20px; /* Adjust space below the line as needed */
    padding-bottom: 20px; /* Adjust space above the line as needed */
}