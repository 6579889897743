.cta-button {
    display: inline-block;
    background-color: var(--green-500);
    color: white;
    font-size: 1.5rem; /* Increase the font size to make the button appear bigger */
    text-decoration: none;
    padding: 16px 32px; /* Increase the padding to make the button appear bigger */
    border-radius: 24px; /* Increase the border-radius to make the corners more rounded */
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: var(--green-400);
  }
  
  .cta-button:active {
    background-color: var(--green-600);
  }
  