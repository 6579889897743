table {
    border: 1px solid #d3d3d3;
    border-collapse: collapse;
    margin: 10px 0;
    width: 100%;
  }
  td, th {
    border: 1px solid #d3d3d3;
    padding: 8px;
    white-space: pre-wrap;
    word-wrap: normal;
  }
  th {
    background-color: #f2f2f2;
    color: #333;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
table td:nth-child(1),
table th:nth-child(1),
table td:nth-child(2),
table th:nth-child(2),
table td:nth-child(3),
table th:nth-child(3),
table td:nth-child(4),
table th:nth-child(4) {
  width: unset;
}
  /* Set the width of Col1 and Col2 to 20% */
  /* table td:nth-child(1), table th:nth-child(1),
  table td:nth-child(2), table th:nth-child(2) {
    width: 15%;
  } */
  
  /* Set the width of Col3 and Col4 to 40% */
  /* table td:nth-child(3), table th:nth-child(3),
  table td:nth-child(4), table th:nth-child(4) {
    width: 35%;
  } */
  