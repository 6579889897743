footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--footer-height);
  background-color: var(--green-100);
  border-top: 1px solid var(--green-300);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: var(--green-900);
}
  
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}