.question-container {
  background-color: var(--green-200);
  padding: 1rem;
  margin-top: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.question-text {
  color: var(--green-900);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  align-content: flex-start;
}

.answer-options {
  list-style-type: none;
  padding: 0;
}

.answer-option {
  background-color: var(--green-50);
  color: var(--green-800);
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  cursor:grab;
  display: flex;
  text-align: left;
  align-content: flex-start;

}

.answer-option:hover {
  background-color: var(--green-200);
}

.answer-option input[type="radio"] {
  margin-right: 0.5rem;
}

.answer-option label {
  display: flex;
  align-items: center;
}

.answer-option.correct {
  background-color: #5d9e4f; /* Bold GREEN */
  color: #fff;
}

.answer-option.incorrect {
  background-color: #e74c3c; /* RED */
  color: #fff;
}