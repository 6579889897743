.writing-practice-section {
    position: fixed;
    left: 20px; /* Adjust the left position as needed */
    top: 20%; /* Adjust the top position as needed */
    width: 200px; /* Adjust the width as needed */
    height: auto; /* Adjust the height as needed */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
    background-color: #f5f5f5; /* Adjust the background color as needed */
    padding: 20px; /* Adjust the padding as needed */
  }
  
  .blog-item-container {
    margin-top: 10px;
    margin-left: 20px; /* Adjust this value as needed */
    max-width: 80%;
  }
  