
div[role="colored-bar"] {
    background-color: var(--green-500); /* Use the green-500 shade for the colored bar */
    height: 5px;
    width: 100%;
  }
  
  @media (min-width: 600px) and (max-width: 900px) {
    div[role="colored-bar"] {
      width: 80%;
    }
  }
  
  ul[role="menu"] {
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
    background-color: var(--green-50); /* Use the green-50 shade for the menu background */
    border-bottom: 1px solid var(--green-100); /* Use the green-100 shade for the menu border */
    padding: 10px;
  }
  
  li[role="menuitem"] {
    display: inline-block;
    margin-right: 20px;
    position: relative;
  }
  
  li[role="menuitem"] a {
    color: var(--green-700); /* Use the green-700 shade for the menu item text color */
    display: block;
    padding: 10px;
    text-decoration: none;
  }
  
  li[role="menuitem"]:hover > .sub-menu {
    display: block;
  }
  
  .sub-menu {
    background-color: var(--green-100); /* Use the green-100 shade for the submenu background */
    border: 1px solid var(--green-200); /* Use the green-200 shade for the submenu border */
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    z-index: 1;
  }
  
  .sub-menu-item {
    border-bottom: 1px solid var(--green-200); /* Use the green-200 shade for the submenu item border */
    margin: 0;
    padding: 10px;
  }
  
  .sub-menu-item:last-child {
    border-bottom: none;
  }
  
  .title-menu {
    display: flex;
    justify-content: space-between;
  }
  