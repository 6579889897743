/* VocabularyList.css */

.vocabulary-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: fit-content;
    margin: 0 auto;
    padding: 24px;
    background-color: var(--green-800);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .vocabulary-list h2 {
    margin-bottom: 8px;
    font-size: 24px;
    color: var(--green-200);
  }
  
  .vocabulary-item {
    display: flex;
    font-size: 18px;
    flex-direction: column;
    margin-bottom: 16px;
  }
  
  .vocabulary-item h3 {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
    color: var(--green-500);
  }
  
  .vocabulary-item .definition {
    margin: 0;
    color: var(--green-100);
    text-align: left;
  }

  .definition-item {
    align-content: flex-start;
    text-align: left;
  }

  
  .vocabulary-item .example {
    margin-top: 8px;
    /* font-style: italic; */
    color: var(--green-100);
    text-align: left;
  }
  
  .vocabulary-item .definition-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  
  .vocabulary-item .example-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  
  .example-item {
    align-content: flex-start;
    text-align: left;
  }
  