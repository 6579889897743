html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

:root {
  --green-50: #f6faf3;
  --green-100: #e4f4da;
  --green-200: #c5e5b5;
  --green-300: #a0d087;
  --green-400: #7bb163;
  --green-500: #5d9e4f;
  --green-600: #4d8644;
  --green-700: #406e3a;
  --green-800: #355c32;
  --green-900: #2a492b;
}

/* Import the Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Import the Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--green-800);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: var(--green-500);
  margin-top: 0;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
}

.content {
  flex: 1;
  width: 100%;
  padding-bottom: 100px;
}

.Footer {
  width: 100%;
}

/* Large Button */
.large-button {
  display: inline-block;
  background-color: var(--green-500);
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.large-button:hover {
  background-color: var(--green-400);
}

.large-button:active {
  background-color: var(--green-600);
}

.large-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(93, 158, 79, 0.5);
}
